import { H, Section } from "@jfrk/react-heading-levels";
import * as titleStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle.module.css";
import clsx from "clsx";
import React from "react";

import { layout } from "../@whitespace/gatsby-theme-wordpress-basic/foundation";
import AccessibilityReport from "../components/AccessibilityReport/AccessibilityReport";
import useA11y from "../hooks/a11yReport";

export default function AccessibilityReportPage() {
  const {
    a11yinstantiation,
    a11ycontactphone,
    a11ycontactemail,
    a11yresponsetime,
    a11ycontacturi,
    a11yorganisation,
    a11ywebsite,
    a11ystatus,
    a11ytestmethod,
    a11ytestmethodauditurl,
    a11ytestmethodreporturl,
    a11yambitiondate,
    a11yreviewdate,
    a11ypublisheddate,
    a11ydeficiency,
  } = useA11y();
  return (
    <article className={clsx(layout.component, layout.componentWidthFull)}>
      <div className={clsx(layout.component, layout.componentWidthNarrow)}>
        {
          <>
            <H className={clsx(titleStyles.component)}>
              Tillgänglighet för {a11ywebsite}
            </H>
            <Section>
              <AccessibilityReport
                a11yStatus={a11ystatus}
                a11yWebsite={a11ywebsite}
                a11yOrganisation={a11yorganisation}
                a11yContactUri={a11ycontacturi && a11ycontacturi.uri}
                a11yResponseTime={a11yresponsetime}
                a11yContactEmail={a11ycontactemail}
                a11yContactPhone={a11ycontactphone}
                a11yTestMethod={a11ytestmethod}
                a11yTestMethodAuditUrl={a11ytestmethodauditurl}
                a11yTestMethodReportUrl={a11ytestmethodreporturl}
                a11yPublishedDate={a11ypublisheddate}
                a11yReviewDate={a11yreviewdate}
                a11yInstantiation={a11yinstantiation}
                a11yAmbitionDate={a11yambitiondate}
                a11yDeficiency={a11ydeficiency}
              />
            </Section>
          </>
        }
      </div>
    </article>
  );
}
