import { Link } from "@whitespace/components";
import { Time } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import * as bodyStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleBody.module.css";
import TextContent from "@whitespace/gatsby-theme-wordpress-basic/src/components/TextContent";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";
import clsx from "clsx";
import React from "react";

export default function AccessibilityReport({
  a11yStatus,
  a11yWebsite,
  a11yOrganisation,
  a11yContactUri,
  a11yResponseTime,
  a11yContactEmail,
  a11yContactPhone,
  a11yTestMethod,
  a11yTestMethodAuditUrl,
  a11yTestMethodReportUrl,
  a11yPublishedDate,
  a11yReviewDate,
  a11yInstantiation,
  a11yAmbitionDate,
  a11yDeficiency,
}) {
  const { processContent } = useHTMLProcessor();
  return (
    <TextContent>
      <div className={clsx(bodyStyles.preamble)}>
        <p>
          {a11yOrganisation} står bakom den här webbplatsen. Vi vill att så
          många som möjligt ska kunna använda webbplatsen. Det här dokumentet
          beskriver hur {a11yWebsite} uppfyller{" "}
          <em>lagen om tillgänglighet till digital offentlig service</em>,
          eventuella kända tillgänglighetsproblem och hur du kan rapportera
          brister till oss så att vi kan åtgärda dem.
        </p>
      </div>
      <h2>Hur tillgänglig är webbplatsen?</h2>
      {a11yStatus && (
        <p>
          {a11yStatus == "notCompliant" ? (
            <>
              Vi är medvetna om att delar av webbplatsen inte är helt
              tillgängliga. Se avsnittet om innehåll som inte är tillgängligt
              nedan för mer information.
            </>
          ) : a11yStatus == "partiallyCompliant" ? (
            <>
              Vi är medvetna om att delar av webbplatsen inte är helt
              tillgängliga. Se avsnittet om innehåll som inte är tillgängligt
              nedan för mer information.
            </>
          ) : (
            a11yStatus == "fullyCompliant" && (
              <>
                Vi har inga kända brister i tillgängligheten för den här
                webbplatsen.
              </>
            )
          )}
        </p>
      )}

      <h2>Vad kan du göra om du inte kan använda delar av webbplatsen?</h2>
      <p>
        Om du behöver innehåll från {a11yWebsite} som inte är tillgängligt för
        dig, men som är undantaget från lagens tillämpningsområde enligt
        beskrivning nedan, kan du <Link to={a11yContactUri}>meddela oss</Link>.
      </p>
      <p>Svarstiden är normalt {a11yResponseTime}.</p>
      <p>Du kan också kontakta oss på följande sätt:</p>
      <ul>
        {a11yContactEmail && (
          <>
            <li>
              skicka e-post till{" "}
              <Link to={`mailto:${a11yContactEmail}`}>{a11yContactEmail}</Link>
            </li>
          </>
        )}
        {a11yContactPhone && (
          <>
            <li>ring {a11yContactPhone}</li>
          </>
        )}
      </ul>
      <h2>Rapportera brister i webbplatsens tillgänglighet</h2>
      <p>
        Vi strävar hela tiden efter att förbättra webbplatsens tillgänglighet.
        Om du upptäcker problem som inte är beskrivna på den här sidan, eller om
        du anser att vi inte uppfyller lagens krav,{" "}
        <Link to={a11yContactUri}>meddela oss</Link> så att vi får veta att
        problemet finns.
      </p>
      <h2>Tillsyn</h2>
      <p>
        Myndigheten för digital förvaltning har ansvaret för tillsyn för lagen
        om tillgänglighet till digital offentlig service. Om du inte är nöjd med
        hur vi hanterar dina synpunkter kan du{" "}
        <Link to="https://www.digg.se/utveckling--innovation/digital-tillganglighet/anmal-bristande-tillganglighet">
          kontakta Myndigheten för digital förvaltning
        </Link>{" "}
        och påtala det.
      </p>
      <h2>Teknisk information om webbplatsens tillgänglighet</h2>
      {a11yStatus && (
        <p>
          {a11yStatus == "notCompliant" ? (
            <>
              Den här webbplatsen är inte förenlig med lagen om tillgänglighet
              till digital offentlig service. Otillgängliga delar beskrivs nedan
            </>
          ) : a11yStatus == "partiallyCompliant" ? (
            <>
              Den här webbplatsen är delvis förenlig med lagen om tillgänglighet
              till digital offentlig service, på grund av de brister som
              beskrivs nedan.
            </>
          ) : (
            a11yStatus == "fullyCompliant" && (
              <>
                Den här webbplatsen är helt förenlig med lagen om tillgänglighet
                till digital offentlig service.
              </>
            )
          )}
        </p>
      )}

      {a11yDeficiency && (
        <>
          <h3>Innehåll som inte är tillgängligt</h3>
          <p>
            Det innehåll som beskrivs nedan är på ett eller annat sätt inte helt
            tillgängligt
          </p>
          {processContent(a11yDeficiency)}
          {/* <h4>Bristande förenlighet med lagkraven</h4> */}
          {a11yAmbitionDate && (
            <>
              <p>
                Vår ambition är att ha åtgärdat alla kända
                tillgänglighetsproblem senast den{" "}
                <Time
                  date={a11yAmbitionDate}
                  format={{
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  }}
                />{" "}
                .
              </p>
            </>
          )}
        </>
      )}

      {a11yTestMethod && (
        <>
          <h2>Hur vi testat webbplatsen</h2>
          <p>{a11yTestMethod}</p>
        </>
      )}
      {a11yReviewDate && (
        <>
          <p>
            Senaste bedömningen gjordes den{" "}
            <Time
              date={a11yReviewDate}
              format={{
                day: "numeric",
                month: "long",
                year: "numeric",
              }}
            />
            {a11yInstantiation && <> och är godkänd av {a11yInstantiation}</>}.
          </p>
        </>
      )}
      <ul>
        {a11yTestMethodAuditUrl && (
          <>
            <li>
              <Link to={`${a11yTestMethodAuditUrl}`}>Granskningsmetod</Link>
            </li>
          </>
        )}
        {a11yTestMethodReportUrl && (
          <>
            <li>
              <Link to={`${a11yTestMethodReportUrl}`}>Granskningsrapport</Link>
            </li>
          </>
        )}
      </ul>

      {a11yPublishedDate && (
        <p>
          Webbplatsen publicerades den{" "}
          <Time
            date={a11yPublishedDate}
            format={{
              day: "numeric",
              month: "long",
              year: "numeric",
            }}
          />
        </p>
      )}
      {a11yReviewDate && (
        <p>
          Redogörelsen uppdaterades senast den{" "}
          <Time
            date={a11yReviewDate}
            format={{
              day: "numeric",
              month: "long",
              year: "numeric",
            }}
          />
        </p>
      )}
    </TextContent>
  );
}
